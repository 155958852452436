import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import React from 'react';
import {useGetNewsFeedsQuery} from 'api';
import AddIcon from '@mui/icons-material/Add';
import NewsFeedDeleteIconButton from './NewsFeedDeleteIconButton';
import NewsFeedEditIconButton from './NewsFeedEditIconButton';
import CreateNewsFeedModal from './NewsFeedCategoryModal';
import {getImageURL} from '../../../utils/imageUrl';

const totalColumns = 3;

function NewsFeedsTable() {
  const {currentData, isFetching, isError, isSuccess} = useGetNewsFeedsQuery();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <CreateNewsFeedModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="News Feed Table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={totalColumns - 1}>News Feeds</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  disableElevation
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"> Title</TableCell>
              <TableCell align="right">Icon</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              !isFetching &&
              Boolean(currentData.data?.length) &&
              currentData?.data?.map(row => (
                <TableRow key={row._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell align="left">{row.title || 'N/A'}</TableCell>
                  <TableCell align="right">
                    <Box
                      component="object"
                      sx={{
                        width: 70,
                        height: 40,
                      }}
                      type="image/svg+xml"
                      data={getImageURL(row.icon, 'icon')}
                      width="70"
                      height="40"
                    >
                      <Box
                        component="img"
                        sx={{width: 70, height: 40, objectFit: 'contain'}}
                        width="70"
                        height="40"
                        src={getImageURL(row.icon, 'icon')}
                        alt={row.title}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <NewsFeedEditIconButton newsFeed={row} />
                    <NewsFeedDeleteIconButton newsFeedId={row._id} />
                  </TableCell>
                </TableRow>
              ))}
            {Boolean(isSuccess && !currentData?.data?.length) && (
              <TableRow>
                <TableCell colSpan={totalColumns}>No News Feed found</TableCell>
              </TableRow>
            )}
            {isError && (
              <TableRow>
                <TableCell colSpan={totalColumns}>
                  <Typography color="error">Failed to fetch News Feed.</Typography>
                </TableCell>
              </TableRow>
            )}
            {isFetching && (
              <TableRow>
                {Array.from(Array(totalColumns).keys()).map(key => (
                  <TableCell key={key}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default NewsFeedsTable;
